/* src/app/components/table-of-contents/table-of-contents.component.scss */
:root {
  --color-brand: #4caf50;
  --color-brand-dark: #306239;
  --color-brand-very-dark: #2f5339;
  --color-brand-hover: #2f5339;
  --color-background: #2c303a;
  --color-background-dark: #1e222b;
  --color-background-very-dark: #000;
  --color-background-panel: #303541;
  --color-text-foreground-hover: #4caf50;
  --color-foreground: #1b1b2f;
  --color-header: #ddd;
  --color-text: #ccc;
  --color-text-background-dark: #ccc;
  --color-text-hover-background-dark: #fff;
  --color-text-hover: #fff;
  --color-text-disable: #999;
  --color-disable: #666;
  --color-disable-light: #444;
  --color-box-shadow: #111;
  --color-icons: #1c2a36;
  --color-scrollbar-track: #222;
  --color-scrollbar-thumb: #444;
  --color-scrollbar-thumb-hover: #555;
  --brightness-default: 1.1;
  --brightness-high: 1.5;
  --color-footer-background: #1b1b2f;
  --color-footer: #ccc;
  --color-footer-hover: #4caf50;
  --color-top-bar-background: #1b1b2f;
  --color-top-bar-hover: #4caf50;
  --color-bible-search-strong: #4caf50;
  --color-bible-verse: #4caf50;
  --color-strong-text-disable: #888;
  --color-strong-text-translation: #ddd;
  --color-strong-text-transliteration: #deb887;
  --color-strong-text-root: #8fbc8f;
  --color-strong-text-number: #bbb;
  --color-strong-text-less-import: #aaa;
  --color-concordance-dialog-text-translation: #7fbce1;
  --color-book-info-1: #7eafc9;
  --color-book-info-2: #70bd63;
  --color-book-info-3: #d4dc73;
  --color-book-info-4: #d28d60;
  --color-bulb: #e7e75b;
  --color-breadcrumb: #ccc;
  --color-input-background: #2c303a;
  --color-chip-background: #ccc;
  --color-chip-hover: #000;
  --color-logo: #fff;
  --color-text-rgb:
    204,
    204,
    204;
  --color-text-hover-rgb:
    204,
    204,
    204;
  --color-brand-rgb:
    76,
    175,
    80;
  --color-background-rgb:
    44,
    48,
    58;
  --color-background-dark-rgb:
    30,
    34,
    43;
  --color-social-gradient-1-rgb:
    38,
    64,
    50;
  --color-social-gradient-2-rgb:
    38,
    64,
    50;
}
@media only screen and (min-width: 1024px) {
  .hidden-desktop {
    display: none !important;
  }
}
@media only screen and (min-width: 480px) and (max-width: 767px) {
  .hidden-landscape {
    display: none !important;
  }
}
@media only screen and (min-width: 320px) and (max-width: 479px) {
  .hidden-portrait {
    display: none !important;
  }
}
@media only screen and (min-width: 1024px) {
  .hidden-desktop-tablet {
    display: none !important;
  }
}
@media only screen and (min-width: 768px) and (max-width: 1023px) {
  .hidden-desktop-tablet {
    display: none !important;
  }
}
@media only screen and (min-width: 480px) and (max-width: 767px) {
  .hidden-landscape-portrait {
    display: none !important;
  }
}
@media only screen and (min-width: 320px) and (max-width: 479px) {
  .hidden-landscape-portrait {
    display: none !important;
  }
}
@media only screen and (min-width: 768px) and (max-width: 1023px) {
  .hidden-tablet-landscape-portrait {
    display: none !important;
  }
}
@media only screen and (min-width: 480px) and (max-width: 767px) {
  .hidden-tablet-landscape-portrait {
    display: none !important;
  }
}
@media only screen and (min-width: 320px) and (max-width: 479px) {
  .hidden-tablet-landscape-portrait {
    display: none !important;
  }
}
@media only screen and (min-width: 1024px) {
  .hidden-desktop-tablet-landscape {
    display: none !important;
  }
}
@media only screen and (min-width: 768px) and (max-width: 1023px) {
  .hidden-desktop-tablet-landscape {
    display: none !important;
  }
}
@media only screen and (min-width: 480px) and (max-width: 767px) {
  .hidden-desktop-tablet-landscape {
    display: none !important;
  }
}
@media only screen and (min-width: 1024px) {
  .hidden {
    display: none !important;
  }
}
@media only screen and (min-width: 768px) and (max-width: 1023px) {
  .hidden {
    display: none !important;
  }
}
@media only screen and (min-width: 480px) and (max-width: 767px) {
  .hidden {
    display: none !important;
  }
}
@media only screen and (min-width: 320px) and (max-width: 479px) {
  .hidden {
    display: none !important;
  }
}
.center.right {
  display: flex;
  justify-content: flex-end;
}
.center.block {
  display: block;
  text-align: center;
}
.flex {
  display: flex;
}
.flex-center {
  display: flex;
  justify-content: center;
  align-items: center;
}
.box-shadow {
  -webkit-box-shadow: 0 5px 6px 0 var(--color-box-shadow);
  -moz-box-shadow: 0 5px 6px 0 0 var(--color-box-shadow);
  box-shadow: 0 5px 6px 0 var(--color-box-shadow);
}
.image-box {
  -webkit-box-shadow: 0 5px 6px 0 var(--color-box-shadow);
  -moz-box-shadow: 0 5px 6px 0 0 var(--color-box-shadow);
  box-shadow: 0 5px 6px 0 var(--color-box-shadow);
  border: 1px solid var(--color-disable);
}
.hover {
  cursor: pointer;
}
:root {
  --color-brand: #4caf50;
  --color-brand-dark: #306239;
  --color-brand-very-dark: #2f5339;
  --color-brand-hover: #2f5339;
  --color-background: #2c303a;
  --color-background-dark: #1e222b;
  --color-background-very-dark: #000;
  --color-background-panel: #303541;
  --color-text-foreground-hover: #4caf50;
  --color-foreground: #1b1b2f;
  --color-header: #ddd;
  --color-text: #ccc;
  --color-text-background-dark: #ccc;
  --color-text-hover-background-dark: #fff;
  --color-text-hover: #fff;
  --color-text-disable: #999;
  --color-disable: #666;
  --color-disable-light: #444;
  --color-box-shadow: #111;
  --color-icons: #1c2a36;
  --color-scrollbar-track: #222;
  --color-scrollbar-thumb: #444;
  --color-scrollbar-thumb-hover: #555;
  --brightness-default: 1.1;
  --brightness-high: 1.5;
  --color-footer-background: #1b1b2f;
  --color-footer: #ccc;
  --color-footer-hover: #4caf50;
  --color-top-bar-background: #1b1b2f;
  --color-top-bar-hover: #4caf50;
  --color-bible-search-strong: #4caf50;
  --color-bible-verse: #4caf50;
  --color-strong-text-disable: #888;
  --color-strong-text-translation: #ddd;
  --color-strong-text-transliteration: #deb887;
  --color-strong-text-root: #8fbc8f;
  --color-strong-text-number: #bbb;
  --color-strong-text-less-import: #aaa;
  --color-concordance-dialog-text-translation: #7fbce1;
  --color-book-info-1: #7eafc9;
  --color-book-info-2: #70bd63;
  --color-book-info-3: #d4dc73;
  --color-book-info-4: #d28d60;
  --color-bulb: #e7e75b;
  --color-breadcrumb: #ccc;
  --color-input-background: #2c303a;
  --color-chip-background: #ccc;
  --color-chip-hover: #000;
  --color-logo: #fff;
  --color-text-rgb:
    204,
    204,
    204;
  --color-text-hover-rgb:
    204,
    204,
    204;
  --color-brand-rgb:
    76,
    175,
    80;
  --color-background-rgb:
    44,
    48,
    58;
  --color-background-dark-rgb:
    30,
    34,
    43;
  --color-social-gradient-1-rgb:
    38,
    64,
    50;
  --color-social-gradient-2-rgb:
    38,
    64,
    50;
}
:root {
  --color-brand: #4caf50;
  --color-brand-dark: #306239;
  --color-brand-very-dark: #2f5339;
  --color-brand-hover: #2f5339;
  --color-background: #2c303a;
  --color-background-dark: #1e222b;
  --color-background-very-dark: #000;
  --color-background-panel: #303541;
  --color-text-foreground-hover: #4caf50;
  --color-foreground: #1b1b2f;
  --color-header: #ddd;
  --color-text: #ccc;
  --color-text-background-dark: #ccc;
  --color-text-hover-background-dark: #fff;
  --color-text-hover: #fff;
  --color-text-disable: #999;
  --color-disable: #666;
  --color-disable-light: #444;
  --color-box-shadow: #111;
  --color-icons: #1c2a36;
  --color-scrollbar-track: #222;
  --color-scrollbar-thumb: #444;
  --color-scrollbar-thumb-hover: #555;
  --brightness-default: 1.1;
  --brightness-high: 1.5;
  --color-footer-background: #1b1b2f;
  --color-footer: #ccc;
  --color-footer-hover: #4caf50;
  --color-top-bar-background: #1b1b2f;
  --color-top-bar-hover: #4caf50;
  --color-bible-search-strong: #4caf50;
  --color-bible-verse: #4caf50;
  --color-strong-text-disable: #888;
  --color-strong-text-translation: #ddd;
  --color-strong-text-transliteration: #deb887;
  --color-strong-text-root: #8fbc8f;
  --color-strong-text-number: #bbb;
  --color-strong-text-less-import: #aaa;
  --color-concordance-dialog-text-translation: #7fbce1;
  --color-book-info-1: #7eafc9;
  --color-book-info-2: #70bd63;
  --color-book-info-3: #d4dc73;
  --color-book-info-4: #d28d60;
  --color-bulb: #e7e75b;
  --color-breadcrumb: #ccc;
  --color-input-background: #2c303a;
  --color-chip-background: #ccc;
  --color-chip-hover: #000;
  --color-logo: #fff;
  --color-text-rgb:
    204,
    204,
    204;
  --color-text-hover-rgb:
    204,
    204,
    204;
  --color-brand-rgb:
    76,
    175,
    80;
  --color-background-rgb:
    44,
    48,
    58;
  --color-background-dark-rgb:
    30,
    34,
    43;
  --color-social-gradient-1-rgb:
    38,
    64,
    50;
  --color-social-gradient-2-rgb:
    38,
    64,
    50;
}
:host .table-of-contents {
  max-width: 1000px;
  margin: 0 auto;
  padding: 1rem 0;
  letter-spacing: 0.5px;
  line-height: 2;
  box-sizing: border-box;
  padding: 0;
  margin: 2rem auto 2rem;
}
@media only screen and (min-width: 320px) and (max-width: 767px) {
  :host .table-of-contents {
    margin-bottom: 1rem;
  }
}
:host .table-of-contents .outer-container {
  background: rgba(var(--color-background-dark-rgb), 0.2);
  border: 2px solid var(--color-disable);
  padding: 0 2rem;
  clip-path: inset(-4px 0px -4px 0);
}
:host .table-of-contents .outer-container.space-container {
  margin-bottom: 2rem;
}
@media only screen and (min-width: 320px) and (max-width: 479px) {
  :host .table-of-contents .outer-container.tree-container {
    padding: 0 1rem 0 0.5rem;
  }
  :host .table-of-contents .outer-container.tree-container h2 {
    margin-left: 0.5rem;
  }
}
@media only screen and (min-width: 320px) and (max-width: 479px) {
  :host .table-of-contents .outer-container {
    padding: 0 1rem;
  }
}
:host .table-of-contents .outer-container h2 {
  display: flex;
  justify-content: space-between;
}
:host .table-of-contents .outer-container h2 .mat-icon.icon {
  width: 2.5rem;
  height: 2.5rem;
  display: flex;
  align-items: center;
}
@media only screen and (min-width: 480px) and (max-width: 767px) {
  :host .table-of-contents .outer-container h2 .mat-icon.icon {
    width: 2rem;
    height: 2rem;
  }
}
@media only screen and (min-width: 320px) and (max-width: 479px) {
  :host .table-of-contents .outer-container h2 .mat-icon.icon {
    width: 2rem;
    height: 2rem;
  }
}
:host .table-of-contents .outer-container h2 .name {
  position: relative;
}
:host .table-of-contents .outer-container h2 .name .anchor {
  cursor: pointer;
  position: absolute;
  height: 100%;
  top: 0;
  right: -2rem;
  transition: 400ms cubic-bezier(0.79, 0, 0.22, 1);
  opacity: 0;
}
:host .table-of-contents .outer-container h2 .name .anchor .mat-icon {
  height: 100%;
  display: flex;
  align-items: center;
  width: 1.5rem;
  transition: 400ms cubic-bezier(0.79, 0, 0.22, 1);
}
:host .table-of-contents .outer-container h2 .name .anchor:hover {
  opacity: 1;
}
:host .table-of-contents .outer-container h2 .name .anchor:hover .mat-icon {
  color: var(--color-brand);
}
:host .table-of-contents .outer-container h2 .name:hover .anchor {
  opacity: 1;
}
:host .table-of-contents .outer-container .inner-container {
  max-width: 1000px;
  margin: 0 auto;
  padding: 1rem 0;
  letter-spacing: 0.5px;
  line-height: 2;
  padding-bottom: 2rem;
}
@media only screen and (min-width: 1024px) {
  :host .table-of-contents .outer-container .inner-container .columns-wrapper {
    display: flex;
  }
  :host .table-of-contents .outer-container .inner-container .columns-wrapper .column-left {
    margin-right: 1rem;
  }
  :host .table-of-contents .outer-container .inner-container .columns-wrapper .column-left,
  :host .table-of-contents .outer-container .inner-container .columns-wrapper .column-right {
    flex: 1 50%;
  }
}
:host .table-of-contents .outer-container .inner-container .wrapper {
  display: flex;
  align-items: center;
}
@media only screen and (min-width: 1024px) {
  :host .table-of-contents .outer-container .inner-container .wrapper {
    padding: 0.5rem 0;
    line-height: 1.4;
  }
}
:host .table-of-contents .outer-container .inner-container .wrapper button,
:host .table-of-contents .outer-container .inner-container .wrapper a {
  all: unset;
  display: flex;
  align-items: center;
}
@media only screen and (min-width: 480px) and (max-width: 767px) {
  :host .table-of-contents .outer-container .inner-container .wrapper button,
  :host .table-of-contents .outer-container .inner-container .wrapper a {
    margin-bottom: 1.2rem;
  }
}
@media only screen and (min-width: 320px) and (max-width: 479px) {
  :host .table-of-contents .outer-container .inner-container .wrapper button,
  :host .table-of-contents .outer-container .inner-container .wrapper a {
    margin-bottom: 1.2rem;
  }
}
:host .table-of-contents .outer-container .inner-container .wrapper button.current .square,
:host .table-of-contents .outer-container .inner-container .wrapper a.current .square {
  background-color: var(--color-brand-dark);
}
:host .table-of-contents .outer-container .inner-container .wrapper button.current .content,
:host .table-of-contents .outer-container .inner-container .wrapper a.current .content {
  color: var(--color-text-disable);
}
:host .table-of-contents .outer-container .inner-container .wrapper button:focus,
:host .table-of-contents .outer-container .inner-container .wrapper a:focus {
  outline: none;
}
:host .table-of-contents .outer-container .inner-container .wrapper button:focus:not(.current),
:host .table-of-contents .outer-container .inner-container .wrapper button:hover:not(.current),
:host .table-of-contents .outer-container .inner-container .wrapper a:focus:not(.current),
:host .table-of-contents .outer-container .inner-container .wrapper a:hover:not(.current) {
  cursor: pointer;
}
:host .table-of-contents .outer-container .inner-container .wrapper button:focus:not(.current) .square,
:host .table-of-contents .outer-container .inner-container .wrapper button:hover:not(.current) .square,
:host .table-of-contents .outer-container .inner-container .wrapper a:focus:not(.current) .square,
:host .table-of-contents .outer-container .inner-container .wrapper a:hover:not(.current) .square {
  background-color: var(--color-brand);
}
:host .table-of-contents .outer-container .inner-container .wrapper button:focus:not(.current) .content,
:host .table-of-contents .outer-container .inner-container .wrapper button:hover:not(.current) .content,
:host .table-of-contents .outer-container .inner-container .wrapper a:focus:not(.current) .content,
:host .table-of-contents .outer-container .inner-container .wrapper a:hover:not(.current) .content {
  color: var(--color-text-hover);
}
:host .table-of-contents .outer-container .inner-container .wrapper button .square,
:host .table-of-contents .outer-container .inner-container .wrapper a .square {
  display: block;
  min-width: 0.8rem;
  width: 0.8rem;
  height: 0.8rem;
  border: 2px solid var(--color-text);
  margin-right: 1rem;
  transition: 400ms cubic-bezier(0.79, 0, 0.22, 1);
}
:host .table-of-contents .outer-container .inner-container .wrapper button .circle,
:host .table-of-contents .outer-container .inner-container .wrapper a .circle {
  border-radius: 50%;
  min-width: 0.6rem;
  width: 0.6rem;
  height: 0.6rem;
}
:host .table-of-contents .outer-container .inner-container .wrapper button .content,
:host .table-of-contents .outer-container .inner-container .wrapper a .content {
  transition: 400ms cubic-bezier(0.79, 0, 0.22, 1);
  font-size: 1.1rem;
}
@media only screen and (min-width: 320px) and (max-width: 479px) {
  :host .table-of-contents .outer-container .inner-container .wrapper button .content,
  :host .table-of-contents .outer-container .inner-container .wrapper a .content {
    font-size: 1rem;
  }
}
@media only screen and (min-width: 320px) and (max-width: 767px) {
  :host .table-of-contents .outer-container .inner-container .wrapper button .content,
  :host .table-of-contents .outer-container .inner-container .wrapper a .content {
    line-height: 1.2;
  }
}
:host-context(.one-column) .table-of-contents .outer-container.table-of-contents .inner-container .columns-wrapper {
  display: block;
}
/*# sourceMappingURL=table-of-contents.component.css.map */
