import { BreakpointObserver } from '@angular/cdk/layout';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnInit, ViewChild } from '@angular/core';
import { CommonModule, ViewportScroller } from '@angular/common';
import { MatTooltipModule } from '@angular/material/tooltip';
import { PageEvent } from '@angular/material/paginator';
import { RouterLink } from '@angular/router';

import { BibliaInfoSearchResult } from '../../services/biblia-info/biblia-info.model';
import { BibliographyService } from '../../services/bibliography.service';
import { Breakpoints } from '../../directives/media/breakpoints.enum';
import { DynamicPageBibliographyItem } from '../../services/dynamic-page/dynamic-page.model';
import { HeaderComponent } from '../header/header/header.component';
import { IsBibliographyContentObjectPipe } from './is-bibliography-content-object.pipe';
import { MatPaginator, MatPaginatorModule } from '@angular/material/paginator';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatTableDataSource, MatTableModule } from '@angular/material/table';
import { MediaDirectivesModule } from '../../directives/media/media-directives.module';
import { ScrollerHandlerComponent } from './scroller-handler/scroller-handler.component';
import { SearchVerseNoPipe } from '../../pages/common/bible-search/table/search-verse-no.pipe';
import { SharedPipesModule } from '../../pipes/shared-pipes.module';
import { SubComponent } from '../utils/sub/sub.component';

@Component({
  selector: 'app-bibliography',
  templateUrl: './bibliography.component.html',
  styleUrls: [
    './bibliography.component.scss',
    '../../pages/common/bible-search/table/bible-search-table.component.scss'
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    CommonModule,
    HeaderComponent,
    IsBibliographyContentObjectPipe,
    MatTooltipModule,
    MatPaginatorModule,
    MatProgressSpinnerModule,
    MatTableModule,
    MediaDirectivesModule,
    RouterLink,
    ScrollerHandlerComponent,
    SearchVerseNoPipe,
    SharedPipesModule,
  ],
})
export class BibliographyComponent extends SubComponent implements OnInit {
  @Input({ required: true }) protected bibliography: DynamicPageBibliographyItem[];

  protected readonly dataSource = new MatTableDataSource<DynamicPageBibliographyItem>([]);
  protected readonly pageSizeOptions = [5, 100];

  protected data: BibliaInfoSearchResult;
  protected displayedColumns = ['position', 'description'];
  protected isLoading = true;

  @ViewChild(MatPaginator) protected readonly paginator: MatPaginator;

  activeItem: number;

  constructor(
    private bibliographyService: BibliographyService,
    private breakpointObserver: BreakpointObserver,
    private cdr: ChangeDetectorRef,
    private viewportScroller: ViewportScroller
  ) {
    super();
  }

  ngOnInit(): void {
    this.initTable();
    this.observePortraitBreakpoints();
    this.observeScrollRequest();
  }

  protected scrollToItem(index: number): void {
    this.viewportScroller.scrollToAnchor(`item${index}`);
  }

  protected scrollToHandle(index: number): void {
    this.viewportScroller.scrollToAnchor(`handle${index}`);
    (document.getElementById(`handle${index}`)
      .children.item(0) as HTMLButtonElement).focus();
  }

  protected paginationChange($event: PageEvent): void {
    const startPage = $event.pageIndex * $event.pageSize + 1;
    const endPage = startPage - 1 + $event.pageSize;
    const pageSize = +endPage - +startPage + 1;
    const pageIndex = (+startPage - 1) / pageSize;
    this.updateDataSource(pageIndex, pageSize);
    this.scrollToTable();
    setTimeout(() => {
      this.paginator.pageIndex = pageIndex;
      this.paginator.pageSize = pageSize;
    });
  }

  private updateDataSource(pageIndex: number, pageSize: number): void {
    this.dataSource.data = this.bibliography.slice(pageSize * pageIndex, pageSize * pageIndex + pageSize);
  }

  private initTable(): void {
    this.bibliography = this.bibliography.map((item, i) => ({ ...item, i }))
    this.dataSource.data = this.bibliography.slice(0, 5);
  }

  private observePortraitBreakpoints(): void {
    this.subscription.add(this.breakpointObserver.observe([`(max-width: ${Breakpoints.LANDSCAPE - 1}px)`])
      .subscribe(value => {
        this.displayedColumns = value.matches ? ['description'] : ['position', 'description'];
        this.cdr.markForCheck();
      }));
  }

  private scrollToTable(): void {
    this.viewportScroller.scrollToAnchor('bibliography-table');
  }

  private observeScrollRequest(): void {
    this.subscription.add(
      this.bibliographyService.scrollTo$.subscribe(anchor => {
        const pageIndex = Math.floor((anchor - 1) / this.paginator.pageSize);
        this.updateDataSource(pageIndex, this.paginator.pageSize);
        this.paginator.pageIndex = pageIndex;
        setTimeout(() => this.scrollToHandle(anchor))
      })
    )
  }
}
