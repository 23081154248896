import { Pipe, PipeTransform } from '@angular/core';

import { DynamicPageBibliographyContent } from '../../services/dynamic-page/dynamic-page.model';

@Pipe({
  name: 'isBibliographyContentObject',
  standalone: true,
})
export class IsBibliographyContentObjectPipe implements PipeTransform {
  transform(content: string | DynamicPageBibliographyContent): boolean {
    return (!content || typeof content === 'string')
      ? false : this.isBibliographyContentObject(content as any);
  }

  private isBibliographyContentObject(object: any): object is DynamicPageBibliographyContent {
    return ('part1' in object || 'part2' in object) && 'title' in object;
  }
}
