import { AfterViewInit, ChangeDetectionStrategy, Component, inject, Input, OnInit, ViewChild } from '@angular/core';
import { animate, style, transition, trigger } from '@angular/animations';
import { BreakpointObserver } from '@angular/cdk/layout';
import { CommonModule, ViewportScroller } from '@angular/common';
import { FlatTreeControl } from '@angular/cdk/tree';
import { MatIconModule } from '@angular/material/icon';
import { MatTree, MatTreeFlatDataSource, MatTreeFlattener, MatTreeModule } from '@angular/material/tree';

import { BreakpointsService } from '../../services/breakpoints.service';
import { FlatTreeNode, TreeNode, TreeType } from './tree.model';
import { MediaDirectivesModule } from '../../directives/media/media-directives.module';
import { SharedPipesModule } from '../../pipes/shared-pipes.module';
import { StateService } from '../../services/state/state.service';
import { TableOfContentsComponent } from '../table-of-contents/table-of-contents.component';

@Component({
  selector: 'app-tree',
  templateUrl: './tree.component.html',
  styleUrls: ['./tree.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  animations: [
    trigger('fadeAnimation', [
      transition('* => 1', [
        style({ opacity:0 }),
        animate(400)
      ])
    ])
  ],
  imports: [
    CommonModule,
    MatIconModule,
    MatTreeModule,
    MediaDirectivesModule,
    SharedPipesModule,
  ]
})
export class TreeComponent implements OnInit, AfterViewInit {
  @Input({ required: true }) protected readonly data: TreeNode[];
  @Input({ required: true }) protected readonly format: TreeType = TreeType.text;

  @ViewChild(MatTree) private readonly tree;

  protected readonly TreeType = TreeType;

  protected readonly breakpointsService = inject(BreakpointsService);
  private readonly breakpointObserver = inject(BreakpointObserver);
  private readonly stateService = inject(StateService);
  private readonly viewportScroller = inject(ViewportScroller);

  ngOnInit(): void {
    this.dataSource.data = this.data;
  }

  ngAfterViewInit(): void {
    this.tree?.treeControl.expandAll();
  }

  protected scrollTo(anchorId: string, i: number): void {
    this.viewportScroller.scrollToAnchor(anchorId);
    this.stateService.setTableOfContentsIndex(i);
    if (this.breakpointObserver.isMatched(['(min-width: 1024px)'])) {
      TableOfContentsComponent.focusOnFirstElementAfterHeader(anchorId);
    }
  }

  protected treeControl = new FlatTreeControl<FlatTreeNode>(
    node => node.level,
    node => node.expandable,
  );

  private transformer = (node: TreeNode, level: number) => {
    return {
      expandable: !!node.children && node.children.length > 0,
      name: node.name,
      level: level,
    };
  };

  private flattener = new MatTreeFlattener(
    this.transformer,
    node => node.level,
    node => node.expandable,
    node => node.children,
  );

  protected dataSource = new MatTreeFlatDataSource(this.treeControl, this.flattener);

  protected hasChild = (_: number, node: FlatTreeNode) => node.expandable;
}
