<mat-tree class="inner-container" [dataSource]="dataSource" [treeControl]="treeControl">
  <mat-tree-node *matTreeNodeDef="let node; let i = i" matTreeNodePadding
    [matTreeNodePaddingIndent]="(breakpointsService.observePortrait | async) ? '1rem' : '1rem'" [@fadeAnimation]="node.level? 1 : 0">
    <button disabled class="disabled-btn"></button>
    <ng-container *ngTemplateOutlet="types; context: { content: node.name, i: i, type: format }"></ng-container>
  </mat-tree-node>
  <mat-tree-node *matTreeNodeDef="let node; let i = i; when: hasChild" matTreeNodePadding [matTreeNodePaddingIndent]="'1rem'" [@fadeAnimation]="node.level? 1 : 0">
    <button class="expand-btn" [class.expanded]="treeControl.isExpanded(node)" matTreeNodeToggle aria-label="Rozwiń spis treści">
      <mat-icon svgIcon="icons:expand"></mat-icon>
    </button>
    <ng-container *ngTemplateOutlet="types; context: { content: node.name, i: i, type: format }"></ng-container>
  </mat-tree-node>
</mat-tree>

<ng-template #types let-content="content" let-type="type" let-i="i">
  @if (TreeType.anchor === type) {
    @if (content | dash | plEn; as anchorId) {
      <button class="anchor-btn" (click)="scrollTo(anchorId, i)" [attr.aria-label]="'przejdź do pozycji spisu ' + (i + 1) + ' ' + content">
        <span *onlyDesktopTabletLandscape class="square circle"></span>
        <span class="content">{{ content }}</span>
      </button>
    }
  }

  @if (TreeType.text === type) {
    {{ content }}
  }
</ng-template>
