import { Injectable } from '@angular/core';

import { Observable, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class TopBarService {
  private readonly _hidden$ = new Subject<boolean>();
  private _isHidden = false;

  get hidden$(): Observable<boolean> {
    return this._hidden$.asObservable();
  }

  toggleHidden(): void {
    this._isHidden = !this._isHidden;
    this._hidden$.next(this._isHidden);
  }
}
