<section class="table-of-contents box-shadow">
  @if (navigation) {
    <div class="outer-container space-container">
      <h2 id="nawigacja">
        <ng-container *ngTemplateOutlet="anchor; context: { anchor: 'nawigacja', title: 'Nawigacja' }"></ng-container>
        <mat-icon class="icon" svgIcon="icons:list"></mat-icon>
      </h2>
      <div class="inner-container">
        @if (navigation.length < 8) {
          @for (item of navigation; track item; let i = $index) {
            <div class="wrapper">
              <ng-container *ngTemplateOutlet="columnSquare; context: { item: item, i: i }"></ng-container>
            </div>
          }
        }
        @if (navigation.length >= 8) {
          <div class="columns-wrapper">
            <div class="column-left">
              @for (item of navigationLeft; track item; let i = $index) {
                <div class="wrapper">
                  <ng-container *ngTemplateOutlet="columnSquare; context: { item: item, i: i }"></ng-container>
                </div>
              }
            </div>
            <div class="column-right">
              @for (item of navigationRight; track item; let i = $index) {
                <div class="wrapper">
                  <ng-container *ngTemplateOutlet="columnSquare; context: { item: item, i: i }"></ng-container>
                </div>
              }
            </div>
          </div>
        }
      </div>
    </div>
  }
</section>

<section class="table-of-contents box-shadow">
  @if (flatContent?.length || treeContent?.length) {
    <div class="outer-container table-of-contents" [class.tree-container]="contents?.asTree"
      >
      <h2 id="spis-tresci">
        <ng-container *ngTemplateOutlet="anchor; context: { anchor: 'spis-tresci', title: 'Spis treści' }"></ng-container>
        <mat-icon class="icon" svgIcon="icons:low_priority"></mat-icon>
      </h2>
      @if (!contents?.asTree) {
        <div class="inner-container">
          @if (flatContent.length < 8) {
            @for (content of flatContent; track content; let i = $index) {
              <div class="wrapper">
                <ng-container *ngTemplateOutlet="columnCircle; context: { content: content, i: i }"></ng-container>
              </div>
            }
          }
          @if (flatContent.length >= 8) {
            <div class="columns-wrapper">
              <div class="column-left">
                @for (content of contentsLeft; track content; let i = $index) {
                  <div class="wrapper">
                    <ng-container *ngTemplateOutlet="columnCircle; context: { content: content, i: i }"></ng-container>
                  </div>
                }
              </div>
              <div class="column-right">
                @for (content of contentsRight; track content; let i = $index) {
                  <div class="wrapper">
                    <ng-container *ngTemplateOutlet="columnCircle; context: { content: content, i: i }"></ng-container>
                  </div>
                }
              </div>
            </div>
          }
        </div>
      }
      @if (contents?.asTree) {
        <div class="inner-container">
          <app-tree *onlyDesktopTabletLandscape [data]="treeContent" [format]="TreeType.anchor"></app-tree>
          <app-tree *onlyPortrait [data]="treeContent" [format]="TreeType.anchor" class="small"></app-tree>
        </div>
      }
    </div>
  }
</section>

<ng-template #columnSquare let-item="item" let-i="i">
  @if (item.link) {
    <a
      [attr.aria-label]="'otwórz część publikacji o numerze ' + (i + 1)"
      [routerLink]="'..' + item.link">
      <span class="square"></span>
      <span class="content">{{ item.title }}</span>
    </a>
  }

  @if (!item.link) {
    <button class="current" tabindex="-1">
      <span class="square"></span>
      <span class="content">{{ item.title }}</span>
    </button>
  }
</ng-template>

<ng-template #columnCircle let-content="content" let-i="i">
  @if (content | dash | plEn; as anchorId) {
    <button (click)="scrollTo(anchorId, i)" [attr.aria-label]="'przejdź do pozycji spisu ' + (i + 1) + ' ' + content">
      <span class="square circle"></span>
      <span class="content">{{ content }}</span>
    </button>
  }
</ng-template>

<ng-template #anchor let-anchor="anchor" let-title="title">
  <ng-container *onlyDesktop>
    <span class="name">{{ title }}
      <span [cdkCopyToClipboard]="url"
        class="anchor"
        [fragment]="anchor"
        routerLink="./"
        tabindex="-1"
        (click)="copy()">
        <mat-icon class="anchor-link" svgIcon="icons:insert_link"></mat-icon>
      </span>
    </span>
  </ng-container>

  <ng-container *onlyPortraitLandscapeTablet>
    <span class="name" [cdkCopyToClipboard]="url" [fragment]="anchor" routerLink="./" (click)="copy()">
      {{ title }}
    </span>
  </ng-container>
</ng-template>
