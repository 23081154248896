/* src/app/components/table-of-contents/scroll-nav/scroll-nav.component.scss */
:root {
  --color-brand: #4caf50;
  --color-brand-dark: #306239;
  --color-brand-very-dark: #2f5339;
  --color-brand-hover: #2f5339;
  --color-background: #2c303a;
  --color-background-dark: #1e222b;
  --color-background-very-dark: #000;
  --color-background-panel: #303541;
  --color-text-foreground-hover: #4caf50;
  --color-foreground: #1b1b2f;
  --color-header: #ddd;
  --color-text: #ccc;
  --color-text-background-dark: #ccc;
  --color-text-hover-background-dark: #fff;
  --color-text-hover: #fff;
  --color-text-disable: #999;
  --color-disable: #666;
  --color-disable-light: #444;
  --color-box-shadow: #111;
  --color-icons: #1c2a36;
  --color-scrollbar-track: #222;
  --color-scrollbar-thumb: #444;
  --color-scrollbar-thumb-hover: #555;
  --brightness-default: 1.1;
  --brightness-high: 1.5;
  --color-footer-background: #1b1b2f;
  --color-footer: #ccc;
  --color-footer-hover: #4caf50;
  --color-top-bar-background: #1b1b2f;
  --color-top-bar-hover: #4caf50;
  --color-bible-search-strong: #4caf50;
  --color-bible-verse: #4caf50;
  --color-strong-text-disable: #888;
  --color-strong-text-translation: #ddd;
  --color-strong-text-transliteration: #deb887;
  --color-strong-text-root: #8fbc8f;
  --color-strong-text-number: #bbb;
  --color-strong-text-less-import: #aaa;
  --color-concordance-dialog-text-translation: #7fbce1;
  --color-book-info-1: #7eafc9;
  --color-book-info-2: #70bd63;
  --color-book-info-3: #d4dc73;
  --color-book-info-4: #d28d60;
  --color-bulb: #e7e75b;
  --color-breadcrumb: #ccc;
  --color-input-background: #2c303a;
  --color-chip-background: #ccc;
  --color-chip-hover: #000;
  --color-logo: #fff;
  --color-text-rgb:
    204,
    204,
    204;
  --color-text-hover-rgb:
    204,
    204,
    204;
  --color-brand-rgb:
    76,
    175,
    80;
  --color-background-rgb:
    44,
    48,
    58;
  --color-background-dark-rgb:
    30,
    34,
    43;
  --color-social-gradient-1-rgb:
    38,
    64,
    50;
  --color-social-gradient-2-rgb:
    38,
    64,
    50;
}
@media only screen and (min-width: 1024px) {
  .hidden-desktop {
    display: none !important;
  }
}
@media only screen and (min-width: 480px) and (max-width: 767px) {
  .hidden-landscape {
    display: none !important;
  }
}
@media only screen and (min-width: 320px) and (max-width: 479px) {
  .hidden-portrait {
    display: none !important;
  }
}
@media only screen and (min-width: 1024px) {
  .hidden-desktop-tablet {
    display: none !important;
  }
}
@media only screen and (min-width: 768px) and (max-width: 1023px) {
  .hidden-desktop-tablet {
    display: none !important;
  }
}
@media only screen and (min-width: 480px) and (max-width: 767px) {
  .hidden-landscape-portrait {
    display: none !important;
  }
}
@media only screen and (min-width: 320px) and (max-width: 479px) {
  .hidden-landscape-portrait {
    display: none !important;
  }
}
@media only screen and (min-width: 768px) and (max-width: 1023px) {
  .hidden-tablet-landscape-portrait {
    display: none !important;
  }
}
@media only screen and (min-width: 480px) and (max-width: 767px) {
  .hidden-tablet-landscape-portrait {
    display: none !important;
  }
}
@media only screen and (min-width: 320px) and (max-width: 479px) {
  .hidden-tablet-landscape-portrait {
    display: none !important;
  }
}
@media only screen and (min-width: 1024px) {
  .hidden-desktop-tablet-landscape {
    display: none !important;
  }
}
@media only screen and (min-width: 768px) and (max-width: 1023px) {
  .hidden-desktop-tablet-landscape {
    display: none !important;
  }
}
@media only screen and (min-width: 480px) and (max-width: 767px) {
  .hidden-desktop-tablet-landscape {
    display: none !important;
  }
}
@media only screen and (min-width: 1024px) {
  .hidden {
    display: none !important;
  }
}
@media only screen and (min-width: 768px) and (max-width: 1023px) {
  .hidden {
    display: none !important;
  }
}
@media only screen and (min-width: 480px) and (max-width: 767px) {
  .hidden {
    display: none !important;
  }
}
@media only screen and (min-width: 320px) and (max-width: 479px) {
  .hidden {
    display: none !important;
  }
}
.center.right {
  display: flex;
  justify-content: flex-end;
}
.center.block {
  display: block;
  text-align: center;
}
.flex {
  display: flex;
}
.flex-center {
  display: flex;
  justify-content: center;
  align-items: center;
}
.box-shadow {
  -webkit-box-shadow: 0 5px 6px 0 var(--color-box-shadow);
  -moz-box-shadow: 0 5px 6px 0 0 var(--color-box-shadow);
  box-shadow: 0 5px 6px 0 var(--color-box-shadow);
}
.image-box {
  -webkit-box-shadow: 0 5px 6px 0 var(--color-box-shadow);
  -moz-box-shadow: 0 5px 6px 0 0 var(--color-box-shadow);
  box-shadow: 0 5px 6px 0 var(--color-box-shadow);
  border: 1px solid var(--color-disable);
}
.hover {
  cursor: pointer;
}
:root {
  --color-brand: #4caf50;
  --color-brand-dark: #306239;
  --color-brand-very-dark: #2f5339;
  --color-brand-hover: #2f5339;
  --color-background: #2c303a;
  --color-background-dark: #1e222b;
  --color-background-very-dark: #000;
  --color-background-panel: #303541;
  --color-text-foreground-hover: #4caf50;
  --color-foreground: #1b1b2f;
  --color-header: #ddd;
  --color-text: #ccc;
  --color-text-background-dark: #ccc;
  --color-text-hover-background-dark: #fff;
  --color-text-hover: #fff;
  --color-text-disable: #999;
  --color-disable: #666;
  --color-disable-light: #444;
  --color-box-shadow: #111;
  --color-icons: #1c2a36;
  --color-scrollbar-track: #222;
  --color-scrollbar-thumb: #444;
  --color-scrollbar-thumb-hover: #555;
  --brightness-default: 1.1;
  --brightness-high: 1.5;
  --color-footer-background: #1b1b2f;
  --color-footer: #ccc;
  --color-footer-hover: #4caf50;
  --color-top-bar-background: #1b1b2f;
  --color-top-bar-hover: #4caf50;
  --color-bible-search-strong: #4caf50;
  --color-bible-verse: #4caf50;
  --color-strong-text-disable: #888;
  --color-strong-text-translation: #ddd;
  --color-strong-text-transliteration: #deb887;
  --color-strong-text-root: #8fbc8f;
  --color-strong-text-number: #bbb;
  --color-strong-text-less-import: #aaa;
  --color-concordance-dialog-text-translation: #7fbce1;
  --color-book-info-1: #7eafc9;
  --color-book-info-2: #70bd63;
  --color-book-info-3: #d4dc73;
  --color-book-info-4: #d28d60;
  --color-bulb: #e7e75b;
  --color-breadcrumb: #ccc;
  --color-input-background: #2c303a;
  --color-chip-background: #ccc;
  --color-chip-hover: #000;
  --color-logo: #fff;
  --color-text-rgb:
    204,
    204,
    204;
  --color-text-hover-rgb:
    204,
    204,
    204;
  --color-brand-rgb:
    76,
    175,
    80;
  --color-background-rgb:
    44,
    48,
    58;
  --color-background-dark-rgb:
    30,
    34,
    43;
  --color-social-gradient-1-rgb:
    38,
    64,
    50;
  --color-social-gradient-2-rgb:
    38,
    64,
    50;
}
:root {
  --color-brand: #4caf50;
  --color-brand-dark: #306239;
  --color-brand-very-dark: #2f5339;
  --color-brand-hover: #2f5339;
  --color-background: #2c303a;
  --color-background-dark: #1e222b;
  --color-background-very-dark: #000;
  --color-background-panel: #303541;
  --color-text-foreground-hover: #4caf50;
  --color-foreground: #1b1b2f;
  --color-header: #ddd;
  --color-text: #ccc;
  --color-text-background-dark: #ccc;
  --color-text-hover-background-dark: #fff;
  --color-text-hover: #fff;
  --color-text-disable: #999;
  --color-disable: #666;
  --color-disable-light: #444;
  --color-box-shadow: #111;
  --color-icons: #1c2a36;
  --color-scrollbar-track: #222;
  --color-scrollbar-thumb: #444;
  --color-scrollbar-thumb-hover: #555;
  --brightness-default: 1.1;
  --brightness-high: 1.5;
  --color-footer-background: #1b1b2f;
  --color-footer: #ccc;
  --color-footer-hover: #4caf50;
  --color-top-bar-background: #1b1b2f;
  --color-top-bar-hover: #4caf50;
  --color-bible-search-strong: #4caf50;
  --color-bible-verse: #4caf50;
  --color-strong-text-disable: #888;
  --color-strong-text-translation: #ddd;
  --color-strong-text-transliteration: #deb887;
  --color-strong-text-root: #8fbc8f;
  --color-strong-text-number: #bbb;
  --color-strong-text-less-import: #aaa;
  --color-concordance-dialog-text-translation: #7fbce1;
  --color-book-info-1: #7eafc9;
  --color-book-info-2: #70bd63;
  --color-book-info-3: #d4dc73;
  --color-book-info-4: #d28d60;
  --color-bulb: #e7e75b;
  --color-breadcrumb: #ccc;
  --color-input-background: #2c303a;
  --color-chip-background: #ccc;
  --color-chip-hover: #000;
  --color-logo: #fff;
  --color-text-rgb:
    204,
    204,
    204;
  --color-text-hover-rgb:
    204,
    204,
    204;
  --color-brand-rgb:
    76,
    175,
    80;
  --color-background-rgb:
    44,
    48,
    58;
  --color-background-dark-rgb:
    30,
    34,
    43;
  --color-social-gradient-1-rgb:
    38,
    64,
    50;
  --color-social-gradient-2-rgb:
    38,
    64,
    50;
}
:host {
  position: fixed;
  right: 2rem;
  top: 8rem;
  max-height: 378px;
  background: rgba(var(--color-background-dark-rgb), 0.1);
  border: 1px solid var(--color-disable);
  padding: 0.5rem 0 0.5rem 0.5rem;
  box-sizing: border-box;
  -webkit-box-shadow: 0 3px 4px 0 var(--color-box-shadow);
  -moz-box-shadow: 0 3px 4px 0 0 var(--color-box-shadow);
  box-shadow: 0 3px 4px 0 var(--color-box-shadow);
  display: flex;
  flex-flow: column;
  align-items: flex-end;
  overflow-y: scroll;
  transition: width 400ms cubic-bezier(0.25, 0.8, 0.25, 1), top 800ms cubic-bezier(0.79, 0, 0.22, 1);
  scrollbar-width: none;
  width: 300px;
}
:host::-webkit-scrollbar {
  display: none;
}
@media only screen and (min-width: 2200px) {
  :host {
    width: calc((100vw - 1000px) / 2 - 80px);
  }
}
@media only screen and (min-width: 2100px) {
  :host {
    width: calc((100vw - 1000px) / 2 - 80px);
  }
}
@media only screen and (min-width: 2000px) {
  :host {
    width: calc((100vw - 1000px) / 2 - 80px);
  }
}
@media only screen and (min-width: 1900px) {
  :host {
    width: calc((100vw - 1000px) / 2 - 80px);
  }
}
@media only screen and (min-width: 1800px) {
  :host {
    width: calc((100vw - 1000px) / 2 - 80px);
  }
}
@media only screen and (min-width: 1700px) {
  :host {
    width: calc((100vw - 1000px) / 2 - 80px);
  }
}
@media only screen and (max-width: 1024px) {
  :host {
    display: none;
  }
}
:host:hover .nav-container {
  opacity: 0.9;
}
:host:hover .mat-icon {
  opacity: 1;
}
:host .nav-container {
  display: flex;
  align-items: center;
  opacity: 0.6;
  transition: 400ms cubic-bezier(0.79, 0, 0.22, 1);
  padding-left: 1rem;
  box-sizing: border-box;
  max-width: 300px;
}
@media only screen and (min-width: 2200px) {
  :host .nav-container {
    max-width: calc((100vw - 1000px) / 2 - 80px);
  }
}
@media only screen and (min-width: 2100px) {
  :host .nav-container {
    max-width: calc((100vw - 1000px) / 2 - 80px);
  }
}
@media only screen and (min-width: 2000px) {
  :host .nav-container {
    max-width: calc((100vw - 1000px) / 2 - 80px);
  }
}
@media only screen and (min-width: 1900px) {
  :host .nav-container {
    max-width: calc((100vw - 1000px) / 2 - 80px);
  }
}
@media only screen and (min-width: 1800px) {
  :host .nav-container {
    max-width: calc((100vw - 1000px) / 2 - 80px);
  }
}
@media only screen and (min-width: 1700px) {
  :host .nav-container {
    max-width: calc((100vw - 1000px) / 2 - 80px);
  }
}
:host .nav-container:focus {
  outline: none;
}
:host .nav-container.active .circle {
  background-color: var(--color-brand);
}
:host .nav-container.active,
:host .nav-container:hover {
  cursor: pointer;
  opacity: 1;
}
:host .nav-container.active .content,
:host .nav-container:hover .content {
  color: var(--color-text-hover);
}
:host .nav-container:hover .circle-wrapper .circle {
  border-color: var(--color-text-hover);
}
:host .nav-container .circle-wrapper {
  padding: 0.7rem;
}
:host .nav-container .circle-wrapper .circle {
  display: block;
  min-width: 0.5rem;
  width: 0.5rem;
  height: 0.5rem;
  border: 2px solid #aaa;
  border-radius: 50%;
  transition: 400ms cubic-bezier(0.79, 0, 0.22, 1);
}
:host .nav-container .content {
  font-size: 1rem;
  letter-spacing: 0.5px;
  line-height: 2;
  transition: 400ms cubic-bezier(0.79, 0, 0.22, 1);
  overflow-x: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
@media only screen and (max-width: 1700px) {
  :host .nav-container .content {
    font-size: 0.9rem;
  }
}
@media only screen and (max-width: 1500px) {
  :host .nav-container .content {
    display: none;
  }
}
:host .mat-icon {
  position: fixed;
  right: calc(2.7rem - 6px);
  top: 6rem;
  opacity: 0;
  transition: opacity 400ms cubic-bezier(0.79, 0, 0.22, 1), top 800ms cubic-bezier(0.79, 0, 0.22, 1);
}
:host .mat-icon.out {
  transform: rotate(180deg);
}
:host .mat-icon:hover {
  cursor: pointer;
  opacity: 1;
}
:host-context(.text-not-visible) {
  width: 2.2rem;
}
@media only screen and (max-width: 1024px) {
  :host-context(.text-not-visible) {
    display: none;
  }
}
:host-context(.text-not-visible) .nav-container {
  cursor: inherit;
}
:host-context(.text-not-visible) .nav-container:hover .circle-wrapper .circle {
  border-color: #aaa;
}
:host-context(.text-not-visible) .nav-container .circle-wrapper {
  cursor: pointer;
}
:host-context(.text-not-visible) .nav-container .circle-wrapper:hover .circle {
  border-color: var(--color-text-hover);
}
:host-context(.arrows-hidden) {
  width: 35px;
}
:host-context(.arrows-hidden) .mat-icon {
  display: none;
}
:host-context(.top-bar-hidden) {
  top: calc(7rem - 60px + 2rem);
}
@media only screen and (min-width: 1024px) {
  :host-context(.top-bar-hidden) {
    top: calc(7rem - 70px + 2rem);
  }
}
:host-context(.top-bar-hidden) .mat-icon {
  top: calc(7rem - 60px);
}
@media only screen and (min-width: 1024px) {
  :host-context(.top-bar-hidden) .mat-icon {
    top: calc(7rem - 70px);
  }
}
/*# sourceMappingURL=scroll-nav.component.css.map */
