<app-header title="Bibliografia"></app-header>

<table id="bibliography-table" mat-table [dataSource]="dataSource">
  <ng-container matColumnDef="position">
    <th mat-header-cell *matHeaderCellDef>Pozycja</th>
    <td mat-cell *matCellDef="let element" class="item-container">
      <ng-container *ngTemplateOutlet="button; context: { element: element }"></ng-container>
    </td>
  </ng-container>

  <ng-container matColumnDef="description">
    <th mat-header-cell *matHeaderCellDef>Opis</th>

    <td mat-cell *matCellDef="let element; let i = index;" class="item-container">
      @if (!(element.content | isBibliographyContentObject)) {
        <span class="text">
        {{ element.content}}</span>
      }

      @if ((element.content | isBibliographyContentObject)) {
        <span class="text">
          @if (element.content.part1) {
            {{ element.content.part1 }}
          }
          <i>{{ element.content.title }}</i>
          {{ element.content.part2 }}
        </span>
      }

      @if (element.link) {
        ,
        <a [href]="element.link" target="_blank" matTooltip="otwiera w nowym oknie">źródło</a>,
        [dostęp: {{ element.access }}]
      }

      <div *onlyPortrait class="position-link">
        <a [id]="'handle' + (element.i + 1)"
          [attr.aria-label]="'bibliografia pozycja ' + (element.i + 1)"
          (click)="scrollToItem(element.i + 1)"
          [class.hover-item]="element.i === activeItem"
          tabindex="0"
          >pozycja {{ element.i + 1 }}
        </a>
      </div>
    </td>
  </ng-container>

  <tr mat-header-row *matHeaderRowDef="displayedColumns">
  </tr>
  <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
</table>

<mat-paginator [pageSizeOptions]="pageSizeOptions" [length]="bibliography.length"
  showFirstLastButtons aria-label="Zmiana strony wyników wyszukiwania"
  (page)="paginationChange($event)"
></mat-paginator>

<ng-template #button let-element="element">
  <div class="index-container" [id]="'handle' + (element.i + 1)">
    <button class="index-circle"
      [class.hover-item]="element.i === activeItem"
      [attr.aria-label]="'bibliografia pozycja ' + (element.i + 1)"
      (click)="scrollToItem(element.i + 1)"
      (focusin)="activeItem = element.i"
      (focusout)="activeItem = null"
      (mouseenter)="activeItem = element.i"
      (mouseleave)="activeItem = null">
      {{ element.i + 1 }}
    </button>
  </div>
</ng-template>
