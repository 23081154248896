import { DOCUMENT } from '@angular/common';
import { Inject, Injectable } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';

import { SeoConfig, SocialsConfig } from './seo.model';

@Injectable({
  providedIn: 'root',
})
export class SeoService {
  constructor(@Inject(DOCUMENT) private document,
              private meta: Meta,
              private title: Title,) {}

  init(config: SeoConfig | SocialsConfig): void {
    if (SeoService.isSocials(config)) {
      this.initSocials(config);
    } else {
      this.initSeo({ title: config.title, description: config.description });
    }
}

  private initSeo(config: SeoConfig): void {
    this.title.setTitle(config.title ? `${config.title} - Apologetyka Biblijna` : 'Apologetyka Biblijna');
    this.meta.updateTag({ name: 'description', content: config.description });
    this.createCanonicalLink();
  }

  private initSocials(config: SocialsConfig): void {
    this.initSeo({ title: config.title, description: config.description });
    this.meta.addTag({ name: 'og:title', content: config.title });
    this.meta.addTag({ name: 'og:type', content: 'website' });
    this.meta.addTag({ name: 'og:image', content: config.ogImage || 'https://apologetykabiblijna.pl/assets/img/logo.jpg' });
    this.meta.addTag({ name: 'og:url', content: config.ogUrl });
    this.meta.addTag({ name: 'twitter:card', content: 'summary_large_image' });
    this.meta.addTag({ name: 'og:description', content: config.description });
    this.meta.addTag({ name: 'og:site_name', content: '@apologetykabiblia' });
    this.meta.addTag({ name: 'twitter:image:alt', content: config.twitterImageAlt });
    this.meta.addTag({ name: 'fb:app_id', content: '368711641975764' });
    this.meta.addTag({ name: 'twitter:site', content: '@ApologBiblijna' });
    this.meta.addTag({ name: 'twitter:creator', content: '@ApologBiblijna' });
    this.meta.addTag({ name: 'twitter:image', content: config.ogImage || 'https://apologetykabiblijna.pl/assets/img/logo.jpg' });
  }

  private static isSocials(object: SeoConfig | SocialsConfig): object is SocialsConfig {
    return 'ogUrl' in object;
  }

  private createCanonicalLink(): void {
    const url = window.location.origin + window.location.pathname;
    const link: HTMLLinkElement = this.document.createElement('link');
    link.setAttribute('rel', 'canonical');
    link.setAttribute('href', url);
    this.document.head.appendChild(link);
  }
}
