@for (headers of headers; track headers; let i = $index) {
  <div
     class="nav-container" [ngClass]="{
    'active': currentIndex === i
  }" (click)="scrollTo(i)">
    @if (headers.textContent.includes('insert_link') ?
      headers.textContent.substring(11) : headers.textContent; as text) {
      <span class="content" [@slideInOut]="contentState">{{ text }}</span>
      <div class="circle-wrapper" [matTooltip]="textNotVisible ? text : null"
        matTooltipPosition="left" (click)="scrollTo(i, true)">
        <span class="circle"></span>
      </div>
    }
  </div>
}

@if (!buttonHidden) {
  <mat-icon
    svgIcon="icons:switch_left"
    [matTooltip]="tooltip"
    matTooltipPosition="above"
    [attr.aria-label]="tooltip"
    [class.out]="contentState === 'out'"
    (click)="arrowClick()">
  </mat-icon>
}
