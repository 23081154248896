import { ClipboardModule } from '@angular/cdk/clipboard';
import { CommonModule } from '@angular/common';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';

import { MediaDirectivesModule } from '../../directives/media/media-directives.module';
import { ScrollNavComponent } from './scroll-nav/scroll-nav.component';
import { SharedPipesModule } from '../../pipes/shared-pipes.module';
import { TableOfContentsComponent } from './table-of-contents.component';
import { TreeComponent } from '../tree/tree.component';

@NgModule({
  declarations: [
    ScrollNavComponent,
    TableOfContentsComponent,
  ],
  imports: [
    ClipboardModule,
    CommonModule,
    MatIconModule,
    MatTooltipModule,
    MediaDirectivesModule,
    SharedPipesModule,
    RouterModule,
    TreeComponent,
  ],
  exports: [
    ScrollNavComponent,
    TableOfContentsComponent,
  ],
})
export class TableOfContentsModule {}
