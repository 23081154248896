<table *onlyDesktop>
  <thead>
    <tr>
      @for (column of table.columns; track column) {
        <th>{{ column.text ?? column }}</th>
      }
    </tr>
  </thead>

  <tbody>
    @for (row of table.rows; track row) {
      <tr>
        @for (element of row; track element) {
          <td>{{ element }}</td>
        }
      </tr>
    }
  </tbody>
</table>

<div *onlyPortraitLandscapeTablet class="overflow-container">
  <table>
    <thead>
      <tr>
        @for (column of table.columns; track column) {
          @if (!column.text) {
            <th>{{ column }}</th>
          }
          @if (column.text) {
            <th [style]="'min-width: ' + column.width">{{ column.text }}</th>
          }
        }
      </tr>
    </thead>

    <tbody>
      @for (row of table.rows; track row) {
        <tr>
          @for (element of row; track element) {
            <td>{{ element }}</td>
          }
        </tr>
      }
    </tbody>
  </table>
</div>
