import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  HostBinding,
  HostListener,
  OnInit,
  Output,
} from '@angular/core';
import { BreakpointObserver } from '@angular/cdk/layout';
import { Router } from '@angular/router';
import { state, style, trigger } from '@angular/animations';
import { ViewportScroller } from '@angular/common';

import { Subscription } from 'rxjs';

import { AppComponent } from '../../../app.component';
import { Breakpoints } from '../../../directives/media/breakpoints.enum';
import { MenuService } from '../../../services/menu.service';
import { StateService } from '../../../services/state/state.service';
import { TopBarService } from '../../../services/top-bar.service';
import { SubComponent } from '../../utils/sub/sub.component';

export type ScrollNavContentState = 'in' | 'out';

/**
 * Mark element with '.scroll-nav' class to add it to navigation.
 */
@Component({
  selector: 'app-scroll-nav',
  templateUrl: './scroll-nav.component.html',
  styleUrls: ['./scroll-nav.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [
    trigger('slideInOut', [
      state('in', style({
        transform: 'translate3d(0,0,0) translateX(0)'
      })),
      state('out', style({
        transform: 'translate3d(100%, 0, 0) translateX(4.7rem)',
      }))
    ]),
  ]
})
export class ScrollNavComponent extends SubComponent implements OnInit, AfterViewInit {
  private _contentState: ScrollNavContentState;

  get contentState(): ScrollNavContentState {
    return this._contentState;
  }

  set contentState(value: ScrollNavContentState) {
    this._contentState = value;
    this.contentState$.emit(value);
    StateService.setItem('scroll-nav', 'content-state', value);
  }

  currentIndex = 0;
  headers: HTMLElement[];

  mouseOverNav = false;
  labelsHiddenManual = false;

  buttonHidden = false;
  tooltip = 'zwiń etykiety';

  @HostBinding('class.text-not-visible') protected textNotVisible = true;
  @HostBinding('class.arrows-hidden') protected arrowsHidden = false;
  @HostBinding('class.top-bar-hidden') protected topBarHidden = false;

  @Output() private readonly contentState$ = new EventEmitter<ScrollNavContentState>();

  private trackScrollPossible = false;

  constructor(private breakpointObserver: BreakpointObserver,
              private cdr: ChangeDetectorRef,
              private element: ElementRef,
              private menuService: MenuService,
              private router: Router,
              private stateService: StateService,
              private topBarService: TopBarService,
              private viewportScroller: ViewportScroller) {
    super();
  }

  ngOnInit(): void {
    this.allowToTrackScrollChanges();
    this.initContentState();
    this.subscription.add(this.observeBreakpointChanges());
    this.subscription.add(this.observeMenuToggle());
    this.subscription.add(this.observeState());
    this.subscription.add(this.observeTopBarHidden());
    this.cdr.markForCheck();
    setTimeout(() => this.cdr.markForCheck(), 0);
  }

  ngAfterViewInit() {
    this.getHeadersExceptLast();
  }

  scrollTo(i: number, onlyCircle = false): void {
    if (this.contentState === 'in' && !onlyCircle || this.contentState === 'out' && onlyCircle) {
      this.viewportScroller.scrollToAnchor(this.headers[i].id);
      this.currentIndex = i;
    }
  }

  arrowClick(): void {
    const contentStateCondition = this.contentState === 'in';
    this.labelsHiddenManual = contentStateCondition;
    this.contentState = contentStateCondition ? 'out': 'in';
    this.textNotVisible = this.labelsHiddenManual && this.breakpointObserver.isMatched(['(min-width: 1500px)']);
    setTimeout(() => this.tooltip = contentStateCondition ? 'rozwiń etykiety' : 'zwiń etykiety', 10);
  }

  @HostListener('window:scroll', ['$event'])
  private trackScrollPosition(): void {
    if(!this.breakpointObserver.isMatched([`(min-width: ${Breakpoints.DESKTOP}px)`])) {
      return;
    }
    if (!this.clearIndexOnFirstScrollToTop()) {
      return;
    }
    if (this.setIndexWhenScrollingDown()) {
      return;
    }
    if (this.setIndexWhenScrollingUp()) {
      return;
    }
    this.setLastIndexWhenScrollIsDown();
  }

  @HostListener('mouseenter', ['$event'])
  private mouseenter(): void {
    this.mouseOverNav = true;
  };

  @HostListener('mouseleave', ['$event'])
  private mouseLeave(event: MouseEvent): void {
    this.mouseOverNav = false;
    if (this.currentIndex >= 5) {
      this.updateSidenavPosition(event.target as HTMLDivElement);
    } else {
      this.updateSidenavPosition(event.target as HTMLDivElement, 0);
    }
  };

  private initContentState(): void {
    let contentState: ScrollNavContentState = StateService.getItem('scroll-nav')['content-state'];
    contentState = (contentState === 'in' || contentState === 'out') ? contentState : 'in';
    this.contentState = this.menuService.isOpen ? 'out' : contentState;
    setTimeout(() => {
      this.textNotVisible = this.contentState === 'out';
      this.cdr.markForCheck();
    });
  }

  private updateSidenavPosition(element: HTMLDivElement, topValue?: number): void {
    const top = topValue || (36.38 * (this.currentIndex - 5));
    setTimeout(() => {
      element.scrollTo({ top, behavior: 'smooth' });
    }, 1000);
  }

  private setLastUrl(): void {
    const url = this.router.url.split('#')[0];
    const fragment = this.headers[this.currentIndex].id;
    this.stateService.setLastUrl(`${url}#${fragment}`);
  }

  private getHeadersExceptLast(): void {
    this.headers = Array.prototype.slice.call(this.element.nativeElement.parentElement.querySelectorAll('.scroll-nav'));
  }

  private allowToTrackScrollChanges(): void {
    setTimeout(() => this.trackScrollPossible = true, 1000);
  }

  private clearIndexOnFirstScrollToTop(): boolean {
    if (window.scrollY === 0) {
      this.trackScrollPossible = true;
    }
    return this.trackScrollPossible;
  }

  private setIndexWhenScrollingDown(): boolean {
    if (window.scrollY >= this.headers[this.currentIndex + 1]?.offsetTop - AppComponent.OFFSET) {
      this.currentIndex++;
      if (!this.mouseOverNav) {
        this.setLastUrl();
        this.updateSidenavPosition(this.element.nativeElement);
      }
      return true;
    }
    return false;
  }

  private setIndexWhenScrollingUp(): boolean {
    if (window.scrollY < this.headers[this.currentIndex - 1]?.offsetTop + AppComponent.OFFSET) {
      this.currentIndex--;
      if (!this.mouseOverNav) {
        this.setLastUrl();
        this.updateSidenavPosition(this.element.nativeElement);
      }
      return true;
    }
    return false;
  }


  private setLastIndexWhenScrollIsDown(): void {
    const pos = (document.documentElement.scrollTop || document.body.scrollTop)
      + document.documentElement.offsetHeight;
    const max = document.documentElement.scrollHeight;

    if (pos === max)   {
      this.currentIndex = this.headers.length - 1;
    }
  }

  private observeBreakpointChanges(): Subscription {
    return this.breakpointObserver.observe(['(min-width: 1500px)', '(min-width: 2000px)'])
      .subscribe(observer => {
        this.buttonHidden = !observer.breakpoints['(min-width: 1500px)'];
        if (!this.menuService.isOpen && observer.breakpoints['(min-width: 1500px)'] === true) {
          this.textNotVisible = false;
        } else {
          this.textNotVisible = !observer.breakpoints['(min-width: 2000px)'];
        }
        this.cdr.markForCheck();
      });
  }

  private observeMenuToggle(): Subscription {
    return this.menuService.toggle$.subscribe(() => {
      if (this.menuService.isOpen) {
        setTimeout(() => this.arrowsHidden = true, 400);
      } else {
        this.arrowsHidden = this.menuService.isOpen;
      }

      if (!this.labelsHiddenManual) {
        this.contentState = this.contentState === 'in' ? 'out' : 'in';

        if (!this.menuService.isOpen && this.breakpointObserver.isMatched(['(min-width: 1500px)'])) {
          this.textNotVisible = false;
        } else {
          this.textNotVisible = !this.breakpointObserver.isMatched(['(min-width: 2000px)']);
        }
        this.cdr.markForCheck();
      }
    });
  }

  private observeState(): Subscription {
    return this.stateService.tableOfContentsIndex$.subscribe(value => {
      if (!this.mouseOverNav) {
        this.currentIndex = value + 1;
        this.updateSidenavPosition(this.element.nativeElement);
        this.cdr.markForCheck();
      }
    });
  }

  private observeTopBarHidden(): Subscription {
    return this.topBarService.hidden$.subscribe(isHidden => {
      this.topBarHidden = isHidden;
      this.cdr.markForCheck();
    });
  }
}
