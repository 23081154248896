import { AfterViewInit, ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import {
  MAT_DIALOG_DATA,
  MatDialogModule,
  MatDialogRef
} from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { ViewportScroller } from '@angular/common';

import { IconButtonComponent } from '../../../../components/icon-button/icon-button.component';
import { SharedPipesModule } from '../../../../pipes/shared-pipes.module';
import { StateService } from '../../../../services/state/state.service';

export interface PageNavDialogResult {
  anchorId: string;
  tableOfContentsIndex: number;
}

@Component({
  templateUrl: './page-nav-dialog.component.html',
  styleUrls: ['./page-nav-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    IconButtonComponent,
    MatDialogModule,
    MatIconModule,
    SharedPipesModule,
  ]
})
export class PageNavDialogComponent implements AfterViewInit {
  constructor(@Inject(MAT_DIALOG_DATA) public contents: string[],
              public dialogRef: MatDialogRef<PageNavDialogComponent, PageNavDialogResult | null>,
              public stateService: StateService,
              private viewportScroller: ViewportScroller) {}

  ngAfterViewInit(): void {
    this.viewportScroller.scrollToAnchor('activePageNavIndex')
  }

  close(anchorId: string, tableOfContentsIndex: number): void {
    this.dialogRef.close({ anchorId, tableOfContentsIndex });
  }
}
