import { ActivatedRoute } from '@angular/router';
import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  HostBinding,
  HostListener,
  inject,
  Input,
  OnInit
} from '@angular/core';
import { MatDialog, MatDialogModule } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { ViewportScroller } from '@angular/common';

import { AppComponent } from '../../../../app.component';
import { BreakpointsService } from '../../../../services/breakpoints.service';
import { DynamicPageTableOfContents } from '../../../../services/dynamic-page/dynamic-page.model';
import { IconButtonComponent } from '../../../../components/icon-button/icon-button.component';
import { PageNavDialogComponent, PageNavDialogResult } from '../nav-dialog/page-nav-dialog.component';
import { StateService } from '../../../../services/state/state.service';

@Component({
  selector: 'app-page-nav-button',
  templateUrl: './page-nav-button.component.html',
  styleUrls: ['./page-nav-button.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
    imports: [
      IconButtonComponent,
      MatDialogModule,
      MatIconModule,
    ],
})
export class PageNavButtonComponent implements OnInit, AfterViewInit {
  @Input({ required: true }) protected readonly tableOfContents: string[] | DynamicPageTableOfContents;

  private readonly breakpointsService = inject(BreakpointsService);
  private readonly dialog = inject(MatDialog);
  private readonly element = inject(ElementRef);
  private readonly route = inject(ActivatedRoute);
  private readonly stateService = inject(StateService);
  private readonly viewportScroller = inject(ViewportScroller);

  protected flatContent: string[] = [];

  @HostBinding('class.visible') protected visible = false;

  private currentIndex: number;
  private headers: HTMLElement[];
  private trackScrollPossible = false;

  private tableOfContentsBottom: number;
  private tableOfContentsElement: Element;

  ngOnInit(): void {
    this.currentIndex = this.stateService.tableOfContentsIndex;
    if (Array.isArray(this.tableOfContents)) {
      this.flatContent = this.tableOfContents;
    } else {
      this.flatContent = this.tableOfContents?.asTree
        ? this.flatTreeContentThreeLevelDeep()
        : this.tableOfContents?.content as string[];
    }

    this.allowToTrackScrollChanges();
    this.tableOfContentsElement = document.querySelector('#table-of-contents').firstElementChild;
    this.updateTableOfContentsBottom();
  }

  ngAfterViewInit(): void {
    this.getHeadersExceptLast();
  }

  private flatTreeContentThreeLevelDeep(): string[] {
    return (this.tableOfContents as DynamicPageTableOfContents)?.content
      .flatMap(el => el?.children ? [el.name, ...el.children] : el.name)
      .flatMap(el => el.name ? el?.children ? [el.name, ...el.children] : el.name : el)
      .flatMap(el => el.name ? el?.children ? [el.name, ...el.children] : el.name : el);
  }

  @HostListener('window:scroll', ['$event'])
  private trackScrollPosition(): void {
    if (!this.breakpointsService.isDesktop) {
      this.visible = window.scrollY > this.tableOfContentsBottom;

      if (!this.clearIndexOnFirstScrollToTop()) {
        return;
      }
      if (this.setIndexWhenScrollingDown()) {
        return;
      }
      if (this.setIndexWhenScrollingUp()) {
        return;
      }
      this.setLastIndexWhenScrollIsDown();
    }
  }

  @HostListener('window:resize', ['$event'])
  private onResize(): void {
    if (!this.breakpointsService.isDesktop) {
      this.updateTableOfContentsBottom();
    }
  }

  protected openPageNav(): void {
    this.dialog.open(PageNavDialogComponent, { data: this.flatContent, panelClass: ['reading', 'full'] })
      .afterClosed().subscribe((result: PageNavDialogResult) => {
        if (result) {
          this.viewportScroller.scrollToAnchor(result.anchorId);
          this.stateService.setTableOfContentsIndex(result.tableOfContentsIndex);
        }
      });
  }

  private updateTableOfContentsBottom(): void {
    this.tableOfContentsBottom = window.scrollY + this.tableOfContentsElement.getBoundingClientRect().bottom;
  }

  private getHeadersExceptLast(): void {
    this.headers = Array.prototype.slice.call(this.element.nativeElement.parentElement.querySelectorAll('.scroll-nav'));
  }

  private clearIndexOnFirstScrollToTop(): boolean {
    if (window.scrollY === 0) {
      this.stateService.setTableOfContentsIndex(0);
      this.trackScrollPossible = true;
    }
    return this.trackScrollPossible;
  }

  private setIndexWhenScrollingDown(): boolean {
    if (window.scrollY >= this.headers[this.currentIndex + 1]?.offsetTop - AppComponent.OFFSET) {
      this.stateService.setTableOfContentsIndex(++this.currentIndex);
      this.setLastUrl();
      return true;
    }
    return false;
  }

  private setIndexWhenScrollingUp(): boolean {
    if (window.scrollY < this.headers[this.currentIndex - 1]?.offsetTop + AppComponent.OFFSET) {
      this.stateService.setTableOfContentsIndex(--this.currentIndex);
      this.setLastUrl();
      return true;
    }
    return false;
  }

  private setLastIndexWhenScrollIsDown(): void {
    const pos = (document.documentElement.scrollTop || document.body.scrollTop)
      + document.documentElement.offsetHeight;
    const max = document.documentElement.scrollHeight;

    if(pos === max)   {
      this.currentIndex = this.headers.length - 1;
      this.stateService.setTableOfContentsIndex(this.currentIndex);
    }
  }

  private setLastUrl(): void {
    const url = this.route.snapshot.url.map(segment => segment.path).join('/');
    const fragment = this.headers[this.currentIndex].id;
    this.stateService.setLastUrl(`/${url}#${fragment}`);
  }

  private allowToTrackScrollChanges(): void {
    setTimeout(() => this.trackScrollPossible = true, 1000);
  }
}
