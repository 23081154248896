<div mat-dialog-title>
  <h1>Spis treści</h1>

  <app-icon-button label="zamknij spis treści" icon="close" class="icon-large close" (click$)="dialogRef.close()"></app-icon-button>
</div>

<div mat-dialog-content>
  @for (element of contents; track element; let i = $index) {
    <div class="wrapper">
      @if (element | dash | plEn; as anchorId) {
        <button (click)="close(anchorId, i)" [attr.aria-label]="'przejdź do pozycji spisu ' + (i + 1) + ' ' + element"
          [id]="i === this.stateService.tableOfContentsIndex ? 'activePageNavIndex' : null">
          <span class="square circle"></span>
          <span class="content">{{ element }}</span>
        </button>
      }
    </div>
  }
</div>
