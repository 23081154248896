import { AfterViewInit, ChangeDetectionStrategy, Component, HostBinding, Input } from '@angular/core';


import { DynamicPageTableContent } from '../../services/dynamic-page/dynamic-page.model';
import { MediaDirectivesModule } from '../../directives/media/media-directives.module';

@Component({
  selector: 'app-simple-table',
  standalone: true,
  templateUrl: './simple-table.component.html',
  styleUrls: ['./simple-table.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [MediaDirectivesModule]
})
export class SimpleTableComponent implements AfterViewInit {
  @Input({ required: true }) protected readonly table: DynamicPageTableContent;
  @HostBinding('class.center') private center = false;

  ngAfterViewInit(): void {
    this.center = this.table.center;
  }
}
