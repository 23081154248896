import { BreakpointObserver } from '@angular/cdk/layout';
import { ChangeDetectionStrategy, Component, HostBinding, inject, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ViewportScroller } from '@angular/common';

import { DynamicPageNavigation, DynamicPageTableOfContents } from '../../services/dynamic-page/dynamic-page.model';
import { environment } from '../../../environments/environment';
import { SnackbarService } from '../snackbar/snackbar.service';
import { SnackBarType } from '../snackbar/snackbar-type.enum';
import { StateService } from '../../services/state/state.service';
import { TreeNode, TreeType } from '../tree/tree.model';

@Component({
  selector: 'app-table-of-contents',
  templateUrl: './table-of-contents.component.html',
  styleUrls: ['./table-of-contents.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TableOfContentsComponent implements OnInit {
  @Input({ required: true }) protected readonly contents: string[] | DynamicPageTableOfContents;
  @Input() protected readonly navigation: DynamicPageNavigation[];

  private breakpointObserver = inject(BreakpointObserver);
  private router = inject(Router);
  private snackbarService = inject(SnackbarService);
  private stateService = inject(StateService);
  private viewportScroller = inject(ViewportScroller);

  protected flatContent: string[];
  protected treeContent: TreeNode[];

  @HostBinding('class.one-column') private oneColumn: boolean;

  protected contentsLeft: string[];
  protected contentsRight: string[];

  protected navigationLeft: DynamicPageNavigation[];
  protected navigationRight: DynamicPageNavigation[];

  protected get url(): string {
    return `${environment.domain}${this.router.routerState.snapshot.url}`;
  }

  protected readonly TreeType = TreeType;

  ngOnInit() {
    if (Array.isArray(this.contents)) {
      this.flatContent = this.contents;
    } else {
      this.oneColumn = false;
      if (this.contents?.asTree) {
        this.treeContent = this.contents?.content as TreeNode[];
      } else {
        this.flatContent = this.contents?.content as string[];
      }
    }
    this.initTemplateData();
  }

  protected copy(): void {
    this.snackbarService.open('Skopiowano link', SnackBarType.LINK, 2000);
  }

  protected scrollTo(anchorId: string, i: number): void {
    this.viewportScroller.scrollToAnchor(anchorId);
    this.stateService.setTableOfContentsIndex(i);
    if (this.breakpointObserver.isMatched(['(min-width: 1024px)'])) {
      TableOfContentsComponent.focusOnFirstElementAfterHeader(anchorId);
    }
  }

  private initTemplateData(): void {
    if (this.navigation) {
      const squareColumnBreak = Math.ceil(this.navigation.length / 2);
      this.navigationLeft = this.navigation.slice(0, squareColumnBreak);
      this.navigationRight = this.navigation.slice(squareColumnBreak);
    }

    if (this.flatContent) {
      const circleColumnBreak = Math.ceil(this.flatContent.length / 2);
      this.contentsLeft = this.flatContent.slice(0, circleColumnBreak);
      this.contentsRight = this.flatContent.slice(circleColumnBreak);
    }
  }

  static focusOnFirstElementAfterHeader(elementId: string): void {
    const header = document.getElementById(elementId)?.parentElement;

    if (!header) {
      return;
    }
    const sibling = header?.nextElementSibling;

    if (sibling) {
      TableOfContentsComponent.focusOnFirstSiblingElement(sibling);
    } else {
      TableOfContentsComponent.focusOnFirstChildElement(header);
    }
  }

  private static focusOnFirstChildElement(element: Element) {
    return element.querySelector('button')
      .focus({ preventScroll: true });
  }

  private static focusOnFirstSiblingElement(sibling: Element) {
    while (sibling) {
      const focusableElement = sibling.querySelector('button');

      if (focusableElement) {
        focusableElement.focus({ preventScroll: true });
        break;
      }
      sibling = sibling.nextElementSibling;
    }
  }
}
