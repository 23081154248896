<link [href]="theme | themeUrl" type="text/css" rel="stylesheet">

<app-top-bar (changeTheme$)="changeTheme($event)"></app-top-bar>

<app-sidenav>
  <div class="outlet-container">
    <router-outlet></router-outlet>
  </div>

  <app-footer *hideFor="[{ urls: ['/biblia', '/strong'], matching: 'startWith' }]"></app-footer>

  <app-simple-footer *hideFor="[{
    urls: ['/biblia', '/strong'],
    matching: 'startWith',
    showFor: true,
    resolutions: [Breakpoints.DESKTOP]
  }]"></app-simple-footer>
</app-sidenav>

<ng-container *hideFor="[
  {
    urls: ['/biblia/przeklady'],
    matching: 'startWith',
    resolutions: [Breakpoints.PORTRAIT, Breakpoints.LANDSCAPE, Breakpoints.DESKTOP]
  },
  {
    urls: ['/strong/ubg'],
    matching: 'startWith',
    resolutions: [Breakpoints.PORTRAIT, Breakpoints.LANDSCAPE, Breakpoints.TABLET]
  }
]">
  <app-scroll-top-button *hideScrollTopButton></app-scroll-top-button>
</ng-container>
