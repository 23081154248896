export enum TreeType {
  anchor,
  text,
}

export interface TreeNode {
  name: string;
  children?: TreeNode[];
}

export interface FlatTreeNode {
  expandable: boolean;
  name: string;
  level: number;
}
